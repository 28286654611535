import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  CFSelect,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
  mobileOrderButton,
} from 'images'
import { dispatch } from 'store'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          h="90VH"
          w="100%"
          image={`url(${mobileHero}) 12% / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
        >
          <CFView textCenter column center w="100%" mt="10%">
            <CFView column center ph="3%" pb="10px">
              <CFImage src={logo} w="150px" alt="Promotions" mb="20px" />
              <CFText color="white" style={{ fontSize: 40 }} raleway xBold>
                NOW TAKING ONLINE ORDERS
              </CFText>
              <CFView mt="15px">
                <LocationSelect />
              </CFView>
            </CFView>
            <CFView column center mt="15px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="95vh"
          maxHeight="700px"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          center
          justifyBetween
        >
          <CFView
            column
            alignCenter
            justifyStart
            w="100%"
            maxWidth="1400px"
            h="600px"
            pt="3.5%"
          >
            <CFView column center>
              <CFImage src={logo} w="160px" alt="Promotions" mb="25px" />
              <CFText color="white" style={{ fontSize: 42 }} raleway xBold>
                NOW TAKING ONLINE ORDERS
              </CFText>
              <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView zIndex={99} mt="15px">
                  <LocationSelect />
                </CFView>
              </CFSelect>
            </CFView>
            <CFView pulsate mt="25px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
